import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import { userRoles } from '@models';

export function useNumberOfMentorsAllowed() {
  const matchLimit = ref(0);
  const defaultProgram = computed(() => store.getters['programs/defaultProgram']);
  const { match_limit } = defaultProgram.value?.program_roles.filter(r => r.role_id === userRoles.MENTOR).shift();
  // eslint-disable-next-line camelcase
  matchLimit.value = match_limit;
  
  return Array.from({length: matchLimit.value}, (_, i) => i + 1);
}
