<template>
  <b-modal
    id="modal-add-user-match-pool"
    v-model="showModal"
    size="xl"
    :title="``"
    centered
    cancel-variant="outline-secondary"
    @hidden="onHidden"
  >
    <b-form>
      <b-row class="mb-1">
        <b-col
          sm="5"
        >
          <div
            v-if="menteeUser && menteeUser.user"
            class="text-center"
          >
            <b-col
              sm="12"
              class="d-flex justify-content-center"
            >
              <b-avatar
                variant="primary"
                :text="userInitials(menteeUser.user)"
                :src="userProfileImage(menteeUser.user)"
                size="8rem"
              />
            </b-col>
            <b-col
              sm="12"
              class="d-flex flex-column align-items-center"
            >
              <h3
                v-b-tooltip.hover.topright="menteeUser.user.full_name"
                class="text-truncate font-weight-bolder mb-0 mt-1"
              >
                {{ menteeUser.user.full_name }}
              </h3>
              <span
                class="mb-0 mt-50"
              ><small
                v-b-tooltip.hover.topright="menteeUser.user.email"
                class="text-muted text-truncate"
              >
                {{ menteeUser.user.email }}</small></span>
              <span
                class="mb-0 mt-50"
              ><small class="text-muted text-capitalize">
                <b-badge
                  variant="light"
                  class="text-muted text-capitalize p-50"
                >
                  {{ roleAlias(menteeUser.role.id, defaultProgram) }}
                </b-badge>
              </small></span>
            </b-col>
          </div>
        </b-col>
        <b-col sm="2">
          <apexchart
            class="grid-item-2"
            type="radialBar"
            height="100"
            :options="options"
            :series="[comparePercentage]"
          />
        </b-col>
        <b-col
          sm="5"
        >
          <b-row v-if="mentorUser && mentorUser.user">
            <b-col
              sm="12"
              class="d-flex justify-content-center"
            >
              <b-avatar
                variant="primary"
                :text="userInitials(mentorUser.user)"
                :src="userProfileImage(mentorUser.user)" 
                size="8rem"
              />
            </b-col>
            <b-col
              sm="12"
              class="d-flex flex-column align-items-center"
            >
              <h3
                v-b-tooltip.hover.topleft="mentorUser.user.full_name"
                class="text-left font-weight-bolder text-truncate mb-0 mt-1"
              >
                {{ mentorUser.user.full_name }}
              </h3>
              <span
                class="mb-0 mt-50"
              ><small
                v-b-tooltip.hover.topleft="mentorUser.user.email"
                class="text-muted text-truncate"
              >
                {{ mentorUser.user.email }}</small></span>
              <span
                class="mb-0 mt-50"
              ><small class="text-muted text-capitalize">
                <b-badge
                  variant="light"
                  class="text-muted text-capitalize p-50"
                >
                  {{ roleAlias(mentorUser.role.id, defaultProgram) }}
                </b-badge>
              </small></span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
        >
          <div class="text-center">
            <section v-if="menteeUser">
              <b-row>
                <b-col sm="6">
                  <hr>
                  <aom-languages style="box-shadow: rgba(34, 41, 47, 0.1) 0px 4px 24px 0px;">
                    <template slot-scope="props">
                      <application-summary
                        :user="menteeUser"
                        :index="props.index"
                        :locale-id="props.localeId"
                        :questions="menteeQuestions"
                        :matched-users-questions="mentorQuestions"
                      />
                    </template>
                  </aom-languages>
                </b-col>
                <b-col sm="6">
                  <hr>
                  <aom-languages style="box-shadow: rgba(34, 41, 47, 0.1) 0px 4px 24px 0px;">
                    <template slot-scope="props">
                      <application-summary
                        :user="mentorUser"
                        :index="props.index"
                        :locale-id="props.localeId"
                        :questions="mentorQuestions"
                        :matched-users-questions="menteeQuestions"
                      />
                    </template>
                  </aom-languages>
                </b-col>
              </b-row>
            </section>
          </div>
        </b-col>
      </b-row>            
    </b-form>
    <template #modal-footer="{ ok, cancel}">
      <b-button
        size="md"
        variant="outline-secondary"
        @click="cancel()"
      >
        Close
      </b-button>
    </template>
  </b-modal>
</template>
    
<script>
import {
  BRow,
  BCol,
  BForm,
  BModal,
  BButton,
  VBToggle,
  BAvatar,
  VBTooltip,
  BBadge
} from "bootstrap-vue";
import {
  getValidationState
} from "@/libs/utils";
import { mapGetters } from 'vuex';
import { userRoles } from '@models';
import { matchesService } from '@services';
import AomLanguages from "@aom-core/AomLanguages";
import ApplicationSummary from "@/views/components/forms/applications/ApplicationSummary.vue";
import { makeErrorToast } from "@/libs/utils";
import { roleAlias } from "@/@aom-core/utils/utils";

export default {
  name: "UserAppSummaryModal",
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BButton,
    BAvatar,
    BBadge,
    AomLanguages,
    ApplicationSummary
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  props: {
    matchUsers: {
      type: Array,
      default:() => [],
      required: true
    },
    comparePercentage: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      menteeUser: {},
      mentorUser: {},
      isLoadingMatches: false,
      matchedUsers: [],
      menteeQuestions: [],
      mentorQuestions: [],
      options: {
        chart: {
          height: 15,
          width: 40,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "14px",
                show: true
              }
            }
          }
        },
      },
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram'])
  },
  watch: {
    matchUsers(n) {
      if(n) {
        this.menteeUser = this.matchUsers.filter(m => m.role_id === userRoles.MENTEE).shift();
        this.mentorUser = this.matchUsers.filter(m => m.role_id === userRoles.MENTOR).shift();
        if(n.length > 0 && this.menteeUser && this.mentorUser) {
          this.fetchMatchUsersQuestions();
        }
      }
    }
  },
  methods: {
    show() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.showModal = false;
      this.menteeUser = {};
      this.mentorUser = {};
      this.menteeQuestions = [];
      this.mentorQuestions = [];
      this.$emit('clear');
    },
    userInitials(user) {
      if(user.first_name && user.last_name) {
        const firstInt = Array.from(user.first_name)[0];
        const lastInt = Array.from(user.last_name)[0];
        return `${firstInt}${lastInt}`;
      }
    },
    userProfileImage (user) {
      return user?.profile_image?.url || '';
    },
    async fetchMatchUsersQuestions() {
      const matchUsers = this.matchUsers.map(u => ({
        user_id: u.user_id,
        role_id: u.role_id
      }));
      const programId = this.$route.params.id;
      try {
        this.isLoadingQuestions = true;
        const response = await matchesService.getCompareUsers(programId, matchUsers);
        const { data } = response;
        const menteeUser = data.match_users.filter(m => m.role_id === userRoles.MENTEE).shift();
        const mentorUser = data.match_users.filter(m => m.role_id === userRoles.MENTOR).shift();
        this.menteeQuestions = menteeUser?.user.user_forms[0]?.form?.questions;
        this.menteeUser.user["profile_image"] = menteeUser?.user.profile_image;
        this.mentorQuestions = mentorUser?.user.user_forms[0]?.form?.questions;
        this.mentorUser.user["profile_image"] = mentorUser?.user.profile_image;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast('Error. Fetching application questions.'));
      } finally {
        this.isLoadingQuestions = false;
        this.$forceUpdate();
      }
    }
  },
  setup() {
    return {
      getValidationState,
      userRoles,
      roleAlias
    };
  },
};
</script>
  <style lang="scss" scoped>
  #matched-users {
      display: grid;
      grid-template-columns: 50% 0 50px auto;
      align-items: center;
  }
  .grid-item-1 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .grid-item-3 {
      justify-self: end;
  }
  .grid-item-4 {
    grid-column: 1 / 5;
  }
  .collapse-panel {
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .search-icon {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-right: none;
    margin-right:-5px;
  }
  .pool-search {
    border-left: none;
  }
  </style>