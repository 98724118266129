var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{ref:"unpublishMatch",attrs:{"id":"unpublishMatch","no-close-on-esc":"","no-close-on-backdrop":"","title":"Cancel Match","centered":""},on:{"ok":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"md","variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"size":"md","variant":"primary"},on:{"click":ok}},[(_vm.isSubmitting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isSubmitting)?_c('span',[_vm._v(" Updating...")]):_c('span',[_vm._v("Unpublish Match")])],1)]}}],null,true),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('p',[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{ref:"reason",attrs:{"rules":"required","name":"Reason"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('v-select',{attrs:{"id":"reason","label":"note","placeholder":"Cancellation reason","options":_vm.reasons,"state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"sm":"12"}},[(_vm.isOtherSelected)?_c('b-form-textarea',{staticClass:"form-control",attrs:{"placeholder":"Reason for cancellation","tabindex":"0"},model:{value:(_vm.otherReason),callback:function ($$v) {_vm.otherReason=$$v},expression:"otherReason"}}):_vm._e()],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }