<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-modal
      v-model="showModal"
      :title="modalTitle"
      centered
      ok-title="Submit"
      cancel-variant="outline-secondary"
      :ok-disabled="isLoading"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-form>
        <p>Would you like to publish now or schedule for later?</p>
        <p>
          If this is a participants last space for matching, then they will be
          removed from any other pools.
        </p>
        <b-form-group>
          <b-form-radio-group
            id="publish-type-radio"
            v-model="selectedMatchStatus"
            :options="matchStatusOptions"
            stacked
          />
        </b-form-group>
        <b-row v-show="showDateTimePickers">
          <b-col>
            <validation-provider
              #default="{ errors }"
              ref="started_at_date"
              name="Match Start Date"
              vid="started_at_date"
              rules="required"
            >
              <b-form-group>
                <aom-date-picker
                  v-model="matchStartDate"
                  :state="errors.length > 0 ? false : null"
                  :min="minStartDate"
                  class="form-control"
                />
              </b-form-group>
              <b-form-invalid-feedback class="text-danger">
                {{
                  errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              ref="started_at_time"
              name="Match Start Time"
              vid="started_at_time"
              rules="required"
            >
              <b-form-group>
                <b-form-timepicker
                  v-model="matchStartTime"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
                />
              </b-form-group>
              <b-form-invalid-feedback class="text-danger">
                {{
                  errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { matchStatus } from "@/models";
import AomDatePicker from "@aom-core/AomDatePicker";
import { localDateAndTimeToUtc } from "@/libs/utils";
import { matchesService } from "@services";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
//eslint-disable-next-line
import { required } from "@validations";
import {
  BForm,
  BModal,
  BFormInvalidFeedback,
  BFormTimepicker,
  BFormRadioGroup,
  BFormGroup,
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BFormInvalidFeedback,
    BForm,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    BFormTimepicker,
    AomDatePicker,
    BFormRadioGroup,
    BRow,
    BCol,
  },
  props: {
    isCreate: {
      type: Boolean,
      default: false,
    },
    matches: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      selectedMatchStatus: matchStatus.ACTIVE,
      matchStartDate: new Date(),
      matchStartTime: "09:00",
      minStartDate: new Date()
    };
  },
  computed: {
    showDateTimePickers() {
      return this.selectedMatchStatus === matchStatus.SCHEDULED;
    },
    modalTitle() {
      return this.matches.length > 1 ? "Publish Matches" : "Publish Match";
    },
    matchStatusOptions() {
      const options = [
        { text: "Publish now.", value: matchStatus.ACTIVE },
        { text: "Schedule for later.", value: matchStatus.SCHEDULED },
      ];
      if (!this.isCreate) {
        options.push({
          text: "Clear any existing schedule.",
          value: matchStatus.LOCKED,
        });
      }
      return options;
    },
  },
  watch: {
    matches: {
      handler(n) {
        if (n && n.length === 1) {
          const match = n[0];
          this.selectedMatchStatus = matchStatus.ACTIVE;
          if (match.status_id === matchStatus.SCHEDULED && match.started_at) {
            this.selectedMatchStatus = match.status_id;
            const date = new Date(match.started_at);
            this.matchStartDate = date;
            this.matchStartTime = date.getHours() + ":" + date.getMinutes();
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    open() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    async onSubmit() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      const utc =
        this.selectedMatchStatus === matchStatus.SCHEDULED
          ? localDateAndTimeToUtc(this.matchStartDate, this.matchStartTime)
          : undefined;

      const programId = this.$route.params.id;

      try {
        if (this.isCreate) {
          const match = {
            status_id: this.selectedMatchStatus,
            started_at: utc,
            match_users: this.matches[0].match_users,
          };
          await matchesService.postCreateMatch(programId, match);
          this.$toast(makeSuccessToast("Match created."));
        } else {
          const data = {
            matches: this.matches.map(m => ({
              id: m.id,
              status_id: this.selectedMatchStatus,
              started_at: utc,
            })),
          };
          await matchesService.patchUpdateMatches(programId, data);
          this.$toast(makeSuccessToast("Match Status updated."));
        }
      
        this.$emit("updated");
        this.showModal = false;
      } catch (e) {
        console.log(e);
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isLoading = false;
      }
    },
    clearForm() {
      this.selectedMatchStatus = matchStatus.ACTIVE;
      this.matchStartDate = new Date();
      this.matchStartTime = "09:00";
    },
  },
};
</script>

<style>
</style>