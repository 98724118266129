<template>
  <b-form-datepicker
    v-model="date"
    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
    :min="min"
    :max="max"
  />
</template>

<script>
import {
  BFormDatepicker
} from "bootstrap-vue";
export default {
  components: {
    BFormDatepicker,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Date],
      default: ""
    },
    max: {
      type: [String, Date],
      default: ""
    },
    min: {
      type: [String, Date],
      default: ""
    }
  },
  data() {
    return {
      date: null,
    };
  },
  watch: {
    value() {
      this.date = this.value;
    },
    date(n) {
      if (n) {
        this.$emit("input", n);
      }
    },
  },
  created() {
    if (this.value) {
      this.date = this.value;
    }
  },
};
</script>
