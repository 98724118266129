<template>
    <section>
        <b-row>
            <b-col md="4" class="d-flex justify-items-center">
                <b-card :class="isActive(['champion-matches-matching-pools', 'champion-matching-pool-details'])">
                    <router-link :to="{
                        name: 'champion-matches-matching-pools'
                    }" class=" d-flex justify-items-center flex-column text-center align-items-center">
                        <span class="progress-number mb-1">1</span>
                        <h4>Matching pools </h4>
                        <p>Participants paired in matching pools</p>
                    </router-link>
                </b-card>

            </b-col>
            
            <b-col md="4" class="d-flex justify-content-center">

                <b-card :class="isActive(['champion-matches-locked-matches'])">
                    <router-link :to="{
                        name: 'champion-matches-locked-matches'
                    }" class=" d-flex justify-items-center flex-column text-center align-items-center">
                        <span class="progress-number mb-1">2</span>
                        <h4>Locked pairs</h4>
                        <p>Pairs locked and ready for publishing</p>
                    </router-link>
                </b-card>
            </b-col>
            <b-col md="4" class="d-flex justify-content-end align-items-end">
                <b-card :class="isActive(['champion-matches-live-matches'])">
                    <router-link :to="{
                        name: 'champion-matches-live-matches'
                    }" class=" d-flex justify-items-center flex-column text-center align-items-center">
                        <span class="progress-number mb-1">3</span>
                        <h4>Live matches</h4>
                        <p>Published and initiated matches</p>
                    </router-link>
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>


<script>
import {
    BCard,
    BRow,
    BCol
} from "bootstrap-vue";

export default {
    name: 'MatchProgress',
    components: {
        BRow,
        BCol,
        BCard
    },
    computed: {
        currentPath() {
            return this.$route.path;
        },
        currentName() {
            return this.$route.name;
        },
        currentQuery() {
            return this.$route.query;
        }
    },
    methods: {
        isActive(progress) {
            return progress.includes(this.$route.name) ? 'progress-card  is-active' : 'progress-card'
        }
    }
}
</script>

<style lang="scss" scoped>

@import '../../../../assets/scss/base/bootstrap-extended'; 

.progress-card {
    width: 95%;
    box-shadow: unset; 
    &:after{
        display:flex;
        position: absolute;
        background-image: url(str-replace(
            str-replace($chevron-right, 'currentColor', #d8d6de),
            '#',
            '%23'
          ));
        content: '';
        top: 50%;
        transform: translateY(-50%);
        right: -50px;
        width: 30px;
        height: 30px;
    
    }
    .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid #d8d6de;
        border-radius: 5px;
    }
}

.progress-number {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid #d8d6de;
}

.is-active {
    border: 2px solid var(--aom-color-secondary);
}
</style>