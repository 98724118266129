<template>
  <section>
    <b-dropdown
      text="Selected Actions"
      variant="primary"
    >
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="bulkSendMessage"
      >
        <feather-icon
          size="16"
          icon="MessageSquareIcon"
          class="mr-50"
        />
        <span>Send Message</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canUnPublish"
        link-class="d-flex align-items-center"
        @click="bulkUnpublish"
      >
        <feather-icon
          size="16"
          icon="DownloadCloudIcon"
          class="mr-50"
        />
        <span>Unpublish</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canPublish"
        link-class="d-flex align-items-center"
        @click="bulkPublish"
      >
        <feather-icon
          size="16"
          icon="UploadCloudIcon"
          class="mr-50"
        />
        <span>Publish</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canLock && !isLockDisabled"
        link-class="d-flex align-items-center"
        @click="bulkLockMatch"
      >
        <feather-icon
          size="16"
          icon="LockIcon"
          class="mr-50"
        />
        <span>Lock</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canUnLock && isUnLockDisabled"
        link-class="d-flex align-items-center"
        @click="bulkUnlockMatch"
      >
        <feather-icon
          size="16"
          icon="UnlockIcon"
          class="mr-50"
        />
        <span>Unlock</span>
      </b-dropdown-item>
    </b-dropdown>
    <b-modal
      id="updating-modal"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-block text-center">
        <b-spinner
          medium
          variant="primary"
          class="mt-2"
        />
        <h5 class="mt-2">
          Updating...
        </h5>
      </div>
    </b-modal>
    <publish-match-modal 
      ref="publishMatchModal"
      :matches="matches"
      @updated="onPublish"
    />
    <unpublish-match-modal 
      ref="unpublishMatchModal"
      :matches="matches"
      :programId="$route.params.id"
      @unpublished="unpublished"
    />
  </section>
</template>
<script>

import UnpublishMatchModal from "../UnpublishMatchModal.vue";
import {  
  BDropdownItem, BDropdown, BModal, BSpinner
} from "bootstrap-vue";
import PublishMatchModal from '../PublishMatchModal.vue';
import { makeSuccessToast, makeErrorToast } from "@/libs/utils"; 
import matchesService from "@/services/matchesService";
import { matchStatus } from "@models";
export default {
  name: 'MatchesBulkActions',
  components: {
    UnpublishMatchModal,
    BDropdownItem,
    BDropdown, 
    BModal,
    BSpinner,
    PublishMatchModal
  },
  props:{
    matches: {
      type: Array,
      default: () => [],
      required: true
    },
    canPublish: {
      type: Boolean
    },
    canUnPublish: {
      type: Boolean
    },
    canLock: {
      type: Boolean
    },
    canUnLock: {
      type: Boolean
    }
  },
  computed: {
    isLockDisabled () {
      return this.matches.filter(m => m.status_id !== matchStatus.NONE).length > 0;
    },
    isUnLockDisabled () {
      return this.matches.filter(m => m.status_id === matchStatus.LOCKED).length > 0;
    }
  },
  methods: {
    bulkSendMessage() {
      if(Array.isArray(this.matches) && this.matches.length > 0) {
        const users = this.matches.map(m => m.match_users.map(u => u.user_id)).flat();
        this.$router.push({name: 'champion-program-communications', query: {user_ids: users}});
      }
    },
    async bulkUnpublish() {
      this.$nextTick(() => {
        this.$refs.unpublishMatchModal.open();
      });
    },
    onPublish() {
      this.$emit('updated');
    },
    unpublished() {
      this.$emit('updated');
    },
    async bulkPublish() {
      this.$refs.publishMatchModal.open();
    },
    async bulkLockMatch() {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to lock this match? If this is a participants last space for matching then they cannot be added to any other pool whilst locked `, {
          title: 'Lock Matches?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Lock',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              this.$bvModal.show('updating-modal');
              const programId = this.$route.params.id;
              const data = {
                matches: this.matches.map(m => ({...m, status_id: matchStatus.LOCKED}))
              };
              const result = await matchesService.patchUpdateMatches(programId, data);
              if (result) {
                this.$toast(makeSuccessToast('Match Status updated.'));
                this.$emit('updated');
              }
            } catch (e) {
              console.log(e);
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
            } finally {
              this.$bvModal.hide('updating-modal');
            }
          }
        });
    },
    async bulkUnlockMatch() {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to Unlock this match? This may cause any unlocked matches in the pool to be shuffled.`, {
          title: 'UnLock Matches?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Unlock',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              this.$bvModal.show('updating-modal');
              const programId = this.$route.params.id;
              const data = {
                matches: this.matches.map(m => ({...m, status_id: matchStatus.NONE}))
              };
              const result = await matchesService.patchUpdateMatches(programId, data);
              if (result) {
                this.$toast(makeSuccessToast('Match Status updated.'));
                this.$emit('updated');
              }
            } catch (e) {
              console.log(e);
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
            } finally {
              this.$bvModal.hide('updating-modal');
            }
          }
        });
    }
  }
};
</script>
       