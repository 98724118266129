<template>
  <div class="d-flex justify-content-between flex-wrap">
    <div class="d-flex align-items-center mb-0 mt-1">
      <span class="text-nowrap"> Showing </span>
      <b-form-select
        :value="perPage"
        :options="['10', '20', '50', '100']"
        class="mx-1"
        @change="(value) => $emit('perPageChanged', value)"
      />
      <span class="text-nowrap"> of {{ total }} entries </span>
    </div>
    <div>
      <b-pagination
        :value="1"
        :total-rows="total"
        :per-page="perPage"
        first-number
        last-number
        align="right"
        prev-text="Previous"
        next-text="Next"
        class="mt-1 mb-0 justify-content-between"
        @input="(value) => $emit('pageChanged', value)"
      />
    </div>
  </div>
</template>

<script>
import {
  BPagination,
  BFormSelect,
} from "bootstrap-vue";

export default {
  components: {
    BPagination,
    BFormSelect, 
  },
  props: {
    total:{
      type: [Number,String],
      default: 0
    },
    perPage: {
      type: [Number, String],
      default: 100
    }
  }
};
</script>
<style lang="scss">
.page-item {
    border-radius: 0;
    margin-right: 0.5em;
    background: none;
}
.page-item:first-child:not(.prev-item) .page-link {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-radius: 0.5rem
}

.page-item:last-child:not(.next-item) .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-radius: 0.5rem
}
.page-item.active .page-link {
    border-radius: 0.5rem !important
}
.page-item.active {
    background-color: #FFF;
}
</style>