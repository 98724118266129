export const matchCancellationReasons = [
  {
    isOther: false,
    note: "Completion of goals early"
  },
  {
    isOther: false,
    note: "Change in circumstances"
  },
  {
    isOther: false,
    note: "Mismatched expectations"
  },
  {
    isOther: false,
    note: "Lack of chemistry/compatibility"
  },
  {
    isOther: false,
    note: "Personal growth/independence"
  },
  {
    isOther: false,
    note: "Breach of policy by either party"
  },
  {
    isOther: false,
    note: "Mentor or mentee dissatisfaction"
  },
  {
    isOther: true,
    note: "Other"
  },
]