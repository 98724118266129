<template>
  <div class="pagination mt-2">
    <b-pagination
      v-model="currentPage"
      size="sm"
      :total-rows="pages"
      :per-page="perPage"
      aria-controls="my-table"
    />
  </div>
</template>
  
<script>
import {
  BPagination
} from "bootstrap-vue";
    
export default {
  name: 'AomPagination',
  components: {
    BPagination
  },
  props: {
    pages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  watch: {
    currentPage(n) {
      if(n) {
        this.$emit("page-change", n);
      }
    }
  }
};
</script>