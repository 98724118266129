<template>
  <section>
    <match-progress></match-progress>
    <b-card class="mt-2">
      <b-row
        align-h="between"
        align-v="center"
      >
        <b-col sm="6">
          <h2 class="mb-1 mb-sm-0">
            {{this.title}}
          </h2>
        </b-col>
        <b-col sm="6">
          
          <div class="d-flex align-items-end justify-content-start justify-content-sm-end">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <b-button
                  variant="outline-secondary"
                >
                  <feather-icon
                    size="16"
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span class="text-nowrap">Export</span>
                </b-button>
              </template>

              <b-dropdown-item @click="onDownloadMatch('csv')">
                <span class="align-middle ml-50">Export CSV</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onDownloadMatch('xlsx')">
                <span class="align-middle ml-50">Export XLSX</span>
              </b-dropdown-item>
            </b-dropdown>
            <action-button
              variant="primary"
              class="ml-2"
              @click="refreshPool"
            >
              <feather-icon
                v-if="!isRefreshing" 
                class="mr-50"
                icon="RefreshCwIcon"
                size="16"
              />
              <b-spinner
                v-if="isRefreshing" 
                small
              />
              <span
                v-if="isRefreshing"
              > Refreshing...</span>
              <span v-else>Refresh data</span>
            </action-button>
            
            <action-button
              variant="primary"
              class="ml-2"
              @click="openMatchPoolSideBar()"
            >
              <feather-icon
                size="16"
                icon="EditIcon"
                class="mr-50"
              />
              <span class="text-nowrap">Edit matching pool</span>
            </action-button>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="bulkSelection.length > 0">
        <b-col
          sm="12"
          class="d-flex justify-content-end mt-2 mb-0"
        >
          <matches-bulk-actions
            :matches="bulkSelection"
            :can-un-lock="true"
            :can-lock="true"
            :can-publish="true"
            @updated="update"
          />
        </b-col>
      </b-row>
      
      <vue-good-table
        ref="poolTable"
        class="mt-3"
        mode="remote"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :rtl="isRtl"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage,
        }"
        style-class="vgt-table striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
      >
        <!-- Headers -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'checked'">
            <b-form-checkbox
              v-model="selectAll"
              :value="true"
              :unchecked-value="false"
              class="mr-2 vgt-checkbox-col"
            />
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>
        <!-- Rows -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field == 'checked' && !props.row.vgtDisabled">
            <b-form-checkbox
              v-model="selected"
              :value="props.row.id"
            />
          </span>
          <span v-else-if="props.column.field === 'compat'">
            <b-link
              v-if="menteeRoleInMatch(props.row) && mentorRoleInMatch(props.row)"
              href="#"
              class="compare-user d-flex align-items-center"
              @click="openCompareModal(props.row)"
            >
        
              <b-progress
                class=""
                :value="props.row.compatibility"
                variant="primary"
                animated
              />{{props.row.compatibility}}%

            </b-link>
            <div  class="compare-user d-flex align-items-center"  v-else>
            <b-progress
              class=""
              :value="props.row.compatibility"
              variant="primary"
              animated
            />{{props.row.compatibility}}%
            </div>
          </span>
          <div
            v-else-if="props.column.field === 'mentee'"
            cols="12"
            md="2"
            class="justify-content-between flex-wrap"
          >
            <div class="align-items-center mb-0 mt-0">
              <b-navbar-nav
                v-if="menteeRoleInMatch(props.row)"
                class="mt-50"
              >
                <b-nav-item-dropdown
                  id="mentee-user-dropdown"
                  variant="link"
                  class="mentee-user-dropdown-wrapper"
                  right  
                  no-caret
                >
                  <template #button-content>
                    <span class="text-body">
                      {{ menteeRoleInMatch(props.row).user.full_name }}
                    </span>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="20"
                    />
                  </template>
                  <b-dropdown-item
                    class="program-dropdown-wrapper"
                    @click="viewProfile(menteeRoleInMatch(props.row).user.id)"
                  >
                    <span class="ml-50">View Profile</span>
                  </b-dropdown-item>
                  <action-dropdown-item
                    v-if="menteeRoleInMatch(props.row) && mentorRoleInMatch(props.row) && !isUserInALockedMatch(props.row)"
                    class="program-dropdown-wrapper"
                    @click="replaceParticipantMentee(props.row)"
                  >
                    <span class="ml-50">Replace Participant</span>
                  </action-dropdown-item>
                  <action-dropdown-item
                    class="program-dropdown-wrapper"
                    @click="removeParticipant(props.row.pool_id, menteeRoleInMatch(props.row))"
                  >
                    <span class="ml-50">Remove Participant</span>
                  </action-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
              <action-button
                v-if="!menteeRoleInMatch(props.row)"
                variant="outline-primary"
                @click="addMentee(props.row)"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                Add {{ roleAlias(userRoles.MENTEE, defaultProgram) }}
              </action-button>
            </div>
          </div>
          <div
            v-else-if="props.column.field === 'mentor'"
            cols="12"
            md="2"
            class="justify-content-between flex-wrap"
          >
            <div class="align-items-center mb-0 mt-0">
              <b-navbar-nav
                v-if="mentorRoleInMatch(props.row)"
                class="mt-50"
              >
                <b-nav-item-dropdown
                  id="mentee-user-dropdown"
                  variant="link"
                  no-caret
                  class="mentee-user-dropdown-wrapper"
                  right  
                >
                  <template #button-content>
                    <span class="text-body">
                      {{ mentorRoleInMatch(props.row).user.full_name }}
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="20"
                      />
                    </span>
                  </template>
                  <b-dropdown-item
                    class="program-dropdown-wrapper"
                    @click="viewProfile(mentorRoleInMatch(props.row).user.id)"
                  >
                    <span class="ml-50">View Profile</span>
                  </b-dropdown-item>
                  <action-dropdown-item
                    v-if="mentorRoleInMatch(props.row) && menteeRoleInMatch(props.row) && !isUserInALockedMatch(props.row)"
                    class="program-dropdown-wrapper"
                    @click="replaceParticipantMentor(props.row)"
                  >
                    <span class="ml-50">Replace Participant</span>
                  </action-dropdown-item>
                  <action-dropdown-item
                    class="program-dropdown-wrapper"
                    @click="removeParticipant(props.row.pool_id, mentorRoleInMatch(props.row))"
                  >
                    <span class="ml-50">Remove Participant</span>
                  </action-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
              <action-button
                v-if="!mentorRoleInMatch(props.row)"
                variant="outline-primary"
                @click="addMentor(props.row)"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                Add {{ roleAlias(userRoles.MENTOR, defaultProgram) }}
              </action-button>
            </div>
          </div>
          <div
            v-else-if="props.column.field === 'is_preferred'"
            cols="12"
            md="2"
            class="justify-content-between flex-wrap vgt-center-align"
          >
            <div class="align-items-center mb-50 mt-50">
              {{ props.row.is_preferred === 1 ? "Yes": props.row.is_preferred === 0 ? "No": "N/A" }}
            </div>
          </div>
          <div
            v-else-if="props.column.field === 'action'"
            cols="12"
            md="2"
            class="justify-content-between flex-wrap vgt-right-align"
          >
            <div class="align-items-center mb-0 mt-0">
              <b-button
                v-if="displayMatchButton('unlockButton', props.row)"
                variant="outline-none"
                class="mr-50"
                size="sm"
                :disabled="!mentorRoleInMatch(props.row) || !menteeRoleInMatch(props.row)"
                @click="lockMatch(props.row.id, props.row)"
              >
                <aom-icon
                  icon="LockOffIcon"
                  size="20"
                />
              </b-button>
              <action-button
                v-if="displayMatchButton('lockButton',props.row)"
                tooltip="Unlock Match"
                size="sm"
                variant="outline-none"
                class="mr-50"
                :disabled="!mentorRoleInMatch(props.row) || !menteeRoleInMatch(props.row)"
                @click="unLockMatch(props.row.id, props.row)"
              >
                <aom-icon
                  icon="LockIcon"
                  size="20"
                />
              </action-button>
              <action-button 
                v-if="mentorRoleInMatch(props.row) && menteeRoleInMatch(props.row)"
                :tooltip="scheduledMatchTooltip(props.row)"
                variant="outline-none"
                class="btn-icon"
                @click="publishMatch(props.row.id, props.row)"
              >
                <feather-icon
                  :icon="isScheduledMatch(props.row) ? 'ClockIcon' : 'UploadCloudIcon'"
                  size="20"
                />
              </action-button>
            </div>
          </div>
          <span v-else-if="props.column.field === 'status'">
            {{ getLockStatus(props.row) }}
          </span>       
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>       
        </template>
  
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <table-pagination
            :per-page="perPage"
            :total="total"
            @perPageChanged="
              (value) => props.perPageChanged({ currentPerPage: value })
            "
            @pageChanged="
              (value) => props.pageChanged({ currentPage: value })
            "
          />
        </template>
      </vue-good-table>
    </b-card>
    <div class="d-flex justify-content-end
    ">

    <router-link
        :to="{ name: 'champion-matches-locked-matches'}"
        class="btn btn-primary btn-icon  mb-4"
    >
    Next
    <feather-icon
      icon="ArrowRightIcon"" 
    />
  </router-link>
            </div>


    <edit-match-pool
      :open="openEditMatchPool"
      :pool="pool"
      @close="closeSideBar"
      @update="update"
    />
    <add-user-to-match-pool
      ref="adduserToMatchPool"
      :user="matchUser"
      :role="matchUserRole"
      :current-user-role="currentUserRole"
      :match="match"
      @published="onUserAdded"
      @clear="clearAddUserToMatchPool"
    />
    <add-user-to-partner-pref-pool
      ref="adduserToPartPrefMatchPool"
      :user="matchUser"
      :role="matchUserRole"
      :current-user-role="currentUserRole"  
      :match="match"
      @published="onUserAdded"
      @clear="clearAddUserToMatchPool"
    />
    
    <b-modal
      id="updating-modal"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-block text-center">
        <b-spinner
          medium
          variant="primary"
          class="mt-2"
        />
        <h5 class="mt-2">
          Updating...
        </h5>
      </div>
    </b-modal>
    <user-application-summary-modal
      ref="userAppSummary"
      :match-users="matchUsers"
      :compare-percentage="comparePercentage"
      @clear="clearModal"
    />
    <publish-match-modal 
      ref="publishMatchModal"
      :matches="matchesToPublish"
      @updated="onPublish"
    />
  </section>
</template>
  
<script>
import {
  BButton,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BSpinner,
  BDropdownItem,
  BNavbarNav,
  BNavItemDropdown,
  BModal,
  BLink,
  BDropdown,
  BFormCheckbox,
  BProgress,
  BAvatar
} from "bootstrap-vue";
import { localeDateStringFromIsoDateTime } from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { userRoles, matchStatus, programTypes } from '@models';
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import matchesService  from "@/services/matchesService";
import { mapGetters } from 'vuex';
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import EditMatchPool from "./EditMatchPool.vue";
import AddUserToMatchPool from "./AddUserToMatchPool.vue";
import UserApplicationSummaryModal from './UserApplicationSummaryModal';
import MatchesBulkActions from "./actions/MatchesBulkActions.vue";
import { BE_API_URL } from "@/constants/app";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import AddUserToPartnerPrefPool from "./AddUserToPartnerPrefPool.vue";
import PublishMatchModal from './PublishMatchModal.vue';
import { roleAlias } from "@/@aom-core/utils/utils";
import MatchProgress from "@/views/apps/champion/matches/MatchProgress.vue";
import AomIcon from "@aom-core/AomIcon";
import ActionButton from "@/views/components/common/ActionButton.vue";
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import { matchStatusDisplayForMatchingPoolSelect } from "@/models/enums/matchStatus";

const HTTP_CONFLICT = 409;

export default {
  name: 'PoolDetails',
  components: {
    ActionDropdownItem,
    ActionButton,
    BCard, 
    BRow,
    BCol,
    BButton,
    BSpinner,
    BDropdownItem, 
    BNavbarNav,
    BNavItemDropdown,
    BModal,
    BLink,
    VueGoodTable,
    TablePagination,
    EditMatchPool,
    AddUserToMatchPool,
    UserApplicationSummaryModal,
    MatchesBulkActions,
    BDropdown,
    BFormCheckbox,
    BAvatar,
    AddUserToPartnerPrefPool,
    PublishMatchModal,
    MatchProgress,
    BProgress,
    AomIcon
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      bulkSelection: [],
      columns: [],
      rows: [],
      columnFilters: [],
      sort: [],
      openEditMatchPool: false,
      title: '',
      lastUpdatedDateTime: undefined,
      isRefreshing: false,
      lockedMatchesCount: 0,
      pendingMatchesCount: 0,
      unMatchedCount: 0,
      options: {
        chart: {
          height: 20  ,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            offsetX: -180,
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true
              }
            }
          }
        },
      },
      matchUser: {},
      matchUserRole: userRoles.MENTEE,
      currentUserRole: userRoles.MENTOR,
      pool: {},
      match: {},
      matchStatus,
      matchUsers: [],
      comparePercentage: 0,
      isSelectAllChecked: false,
      selected: [],
      selectAll: false,
      matchesToPublish: [],
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    isRtl() {
      return store.state.appConfig.isRTL;
    },
    matchCount () {
      return Number(this.lockedMatchesCount) + Number(this.pendingMatchesCount);
    },
    isProgramPartnerPreferencing() {
      return this.defaultProgram?.type_id === programTypes.PARTNER_PREFERENCING;
    }
  },
  watch: {
    selectAll() {
      this.selectAllChange();
    },
    selected(n) {
      if(n) {
        this.selectBulkChange(n);
      }
      
    },
    defaultProgram(n){
      this.columns = [
      {
        field: 'checked',
        width: "1em",
      },
      {
        label: roleAlias(userRoles.MENTEE, this.defaultProgram),
        field: 'mentee',
        filterOptions: {
          enabled: true,
          placeholder: roleAlias(userRoles.MENTEE, this.defaultProgram),
        },
        width: "18em",
      },
      {
        label: roleAlias(userRoles.MENTOR, this.defaultProgram),
        field: 'mentor',
        filterOptions: {
          enabled: true,
          placeholder: roleAlias(userRoles.MENTOR, this.defaultProgram)
        },
        width: "18em",
      },
      {
        label: "Compatibility",
        field: 'compat',
        tdClass: 'text-center mx-auto',
        filterOptions: {
          enabled: true,
          placeholder: "Compatibility"
        },
        width: "8em",
      },
      {
        label: "Lock Status",
        field: 'status',
        tdClass: 'text-center mx-auto',
        filterOptions: {
          enabled: true,
          placeholder: "All",
          filterDropdownItems: matchStatusDisplayForMatchingPoolSelect
        },
        width: "8em",
      },
      {
        label: "Preferred",
        field: 'is_preferred',
        tdClass: 'text-center mx-auto',
        hidden: !this.isProgramPartnerPreferencing,
        filterOptions: {
          enabled: true,
          placeholder: "All pairs",
          filterDropdownItems: [
            { value: 1, text: 'Yes' },
            { value: 0, text: 'No' },
            { value: 'na', text: 'N/A' },
          ],
        },
        width: "8em",
      },
      {
        field: "action",
        sortable: false,
        width: "8em"
      }
    ];
   
    this.update();
    }
  },
  methods: {
    onPublish() {
      this.matchesToPublish = [];
      this.loadItems();
      this.getPoolMatches();
    },
    isScheduledMatch(match) {
      return match.status_id === matchStatus.SCHEDULED;
    },
    isUserInALockedMatch(user) {
      return user.status_id === matchStatus.LOCKED;
    },
    clearModal () {
      this.matchUsers = [];
      this.comparePercentage = 0;
    },
    openCompareModal(args) {
      const { match_users, compatibility } = args;
      // eslint-disable-next-line camelcase
      this.matchUsers = match_users;
      this.comparePercentage = compatibility;
      this.$refs.userAppSummary.show();
    },
    clearAddUserToMatchPool() {
      this.matchUser = {};
      this.match = {};
      this.currentUserRole = userRoles.MENTOR;
    },
    update() {
      this.selectAll = false;
      this.loadItems();
      this.getPoolMatches();
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
      this.getPoolMatches();
    },
  
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
      this.getPoolMatches();
    },
  
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if(type === 'none') {
        this.sort = [];
      }
      else if(field === 'mentee') {
        this.sort = [{ field: 'user.0.full_name', type: type }];
      }
      else if(field === 'mentor') {
        this.sort = [{ field: 'user.1.full_name', type: type }];
      }
      else if(field === 'compat') {
        this.sort = [{ field: 'compatibility', type: type }];
      } else if(field === 'is_preferred') {
        this.sort = [{ field: 'is_preferred', type: type }];
      }
      else if(field === 'is_preferred') {
        this.sort = [{ field: 'is_preferred', type: type }];
      }
      else if(field === 'status') {
        this.sort = [{ field: 'status', type: type }];
      }
      
      this.loadItems();
      this.getPoolMatches();
    },
  
    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "compat",
        "mentee",
        "mentor",
        "is_preferred",
        "status"
      ]) {
        if (params.columnFilters[col]) {
          if(col === 'mentee') {
            columnFilters.push({
              field: 'user.0.full_name',
              value: params.columnFilters[col],
            });
          } else if (col === 'mentor') {
            columnFilters.push({
              field: 'user.1.full_name',
              value: params.columnFilters[col],
            });
          } else if (col === 'is_preferred') {
            columnFilters.push({
              field: 'is_preferred',
              value: params.columnFilters[col],
            });
          } else if (col === 'status') {
            columnFilters.push({
              field: 'status',
              value: parseInt(params.columnFilters[col]),
            });
          } else {
            columnFilters.push({
              field: 'compatibility',
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.getPoolMatches();
    },
    async loadItems() {
      const poolId = this.$route.params.poolId;
      try {
        this.isLoading = true;
        const response = await matchesService.getMatchPool(this.defaultProgramId, poolId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { title, unmatched_matches_count, updated_at, locked_matches_count, pending_matches_count }  = response.data;
        this.pool = response.data;
        this.title = title;
      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      } 
    },
    removeParticipant (poolId, matchUser) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to remove this participant from the pool? Any unlocked matches may be shuffled.', {
          title: 'Remove Participant?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Remove',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              this.$bvModal.show('updating-modal');
              await matchesService.deletePoolUser(this.defaultProgramId, poolId, matchUser.user_id, matchUser.role_id);
              this.$toast(makeSuccessToast('Pool user removed successfully.'));
              this.loadItems();
              this.getPoolMatches();
            } catch (e) {
              const {data, status} = e.response;
              if(status === HTTP_CONFLICT) {
                return  this.$toast(makeErrorToast(`${data?.message}`));
              }
              this.$toast(makeErrorToast('Something went wrong. Pool user not removed.'));
              this.$log.error(e);
            } finally {
              this.$bvModal.hide('updating-modal');
            }
          }
        });         
    },
    openMatchPoolSideBar() {
      this.$route.params.id;
      this.openEditMatchPool = true;
    },
    closeSideBar() {
      this.openEditMatchPool = false;
    },
    async refreshPool() {
      const poolId = this.$route.params.poolId;  
      try {
        this.isRefreshing = true;
        const response = await matchesService.patchMatchPool(this.defaultProgramId, poolId);
        const { updated_at }  = response.data;
        // eslint-disable-next-line camelcase
        this.lastUpdatedDateTime = localeDateStringFromIsoDateTime(updated_at, true);
        this.$toast(makeSuccessToast('Pool refreshed successfully.'));
        await this.update();
      } catch(e) {
        console.log(e);
        this.$toast(makeErrorToast('Something went wrong. Pool not refreshed.'));
      } finally {
        this.isRefreshing = false;
      }
    },
    async getPoolMatches() {
      const poolId = this.$route.params.poolId;  
      try {
        this.isLoading = true;
        const response = await matchesService.getPoolMatches(this.defaultProgramId, poolId,  {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { items, total }  = response.data;
        this.total = total;
        this.rows = items;
        this.rows = this.rows.map(r => {
          if(r.match_users.length > 1) {
            return r;
          } else {
            return {...r, vgtDisabled:true};
          }
        });
      } catch(e) {
        console.log(e);
        this.$toast(makeErrorToast('Something went wrong. Pool matches not fetched.'));
      } finally {
        this.isLoading = false;
      }
    },
    menteeRoleInMatch(matches) {
      if(matches && matches.match_users.length > 0) {
        const mentee = matches.match_users.filter(m => m.role_id === userRoles.MENTEE);
        if(mentee.length > 0) {
          return mentee[0];
        }
        return;
      }
    },
    mentorRoleInMatch(matches) {
      if(matches && matches.match_users.length > 0) {
        const mentor = matches.match_users.filter(m => m.role_id === userRoles.MENTOR);
        if(mentor.length > 0) {
          return mentor[0];
        }
        return;
      }
    },
    viewProfile(id) {
      this.$router.push({ name: 'champion-program-participant',  params: { id: this.defaultProgramId, participantId: id }});
    },
    replaceParticipantMentor(match) {
      this.addMentor(match);
    },
    replaceParticipantMentee(match) {
      this.addMentee(match);
    },
    addMentor(match) {
      const mentee = match.match_users.filter(m => m.role_id === userRoles.MENTEE).shift();
      if(mentee) {
        this.matchUser = mentee?.user;
        this.matchUserRole = userRoles.MENTOR;
        this.currentUserRole = userRoles.MENTEE;
        this.match = match;
      }
      if(this.isProgramPartnerPreferencing) {
        this.$refs.adduserToPartPrefMatchPool.show();
      } else {
        this.$refs.adduserToMatchPool.show();
      }
    },
    addMentee(match) {
      const mentor = match.match_users.filter(m => m.role_id === userRoles.MENTOR).shift();
      if(mentor) {
        this.matchUser = mentor?.user;
        this.matchUserRole = userRoles.MENTEE;
        this.currentUserRole = userRoles.MENTOR;
        this.match = match;
      }
      if(this.isProgramPartnerPreferencing) {
        this.$refs.adduserToPartPrefMatchPool.show();
      } else {
        this.$refs.adduserToMatchPool.show();
      }
    },
    onUserAdded() {
      this.loadItems();
      this.getPoolMatches();
    },
    publishMatch (matchId, match) {
      this.matchesToPublish = [match];
      this.$nextTick(() => {
        this.$refs.publishMatchModal.open();
      });
    },
    async lockMatch(matchId) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to lock this match? If this is a participants last space for matching then they cannot be added to any other pool whilst locked.', {
          title: 'Lock this match?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Lock Match',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          noCloseOnBackdrop: true,
          id: 'unlock-modal'
        })
        .then(async value => {
          if(value) {
            const data = { status_id: matchStatus.LOCKED };
            try {
              this.$bvModal.show('updating-modal');
              await matchesService.patchUpdateMatch(this.defaultProgramId, matchId, data);
              this.$toast(makeSuccessToast('Match Locked successfully.'));
              this.loadItems();
              this.getPoolMatches();
            } catch (e) {
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
              this.$log.error(e);
            } finally {
              this.$bvModal.hide('updating-modal');
            }
          }
        });         
    },
    unLockMatch (matchId, match) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to unlock this match? This may cause any unlocked matches in the pool to be shuffled.', {
          title: 'Unlock this Match ?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Unlock Match',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          noCloseOnBackdrop: true,
          id: 'unlock-modal'
        })
        .then(async value => {
          if(value) {
            const data = { ...match, status_id: matchStatus.NONE };
            try {
              this.$bvModal.show('updating-modal');
              await matchesService.patchUpdateMatch(this.defaultProgramId, matchId, data);
              this.$toast(makeSuccessToast('Match Unlocked successfully.'));
              this.loadItems();
              this.getPoolMatches();
            } catch (e) {
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
              this.$log.error(e);
            } finally {
              this.$bvModal.hide('updating-modal');
            }
          }
        });         
    },
    selectBulkChange(n) {
      if(n.length === 0) {
        this.bulkSelection = [];
        this.selectAll = false;
        return;
      };
      const rowsWithMatches = this.rows.filter(r => r.match_users.length > 1);
      const rows = this.rows.filter(r => r.match_users.length > 1 && n.includes(r.id));
      if(rowsWithMatches.length === rows.length) {
        this.selectAll = true;
      }
      this.bulkSelection = rows;
    },
    selectAllChange() {
      if(!this.selectAll) {
        this.selected = [];
        return;
      }
      const rows = this.rows.filter(r => r.match_users.length > 1);
      rows.forEach(r => {
        if(!this.selected.includes(r.id) || this.selected.length === 0) {
          this.selected.push(r.id);
        }
      });
      this.bulkSelection = rows;

    },
    onDownloadMatch(type) {
      const poolId = this.$route.params.poolId;
      const dowloadLink =
        `${BE_API_URL}/programs/${this.$route.params.id}/pools/${poolId}/matches/download/${type}` +
        `?search=${encodeURIComponent(
          JSON.stringify({
            page: this.page,
            columnFilters: this.columnFilters,
            sort: this.sort,
          })
        )}`;
      window.document.location = dowloadLink;
    },
    scheduledMatchTooltip(match) {
      if (this.isScheduledMatch(match)) {
        return localeDateStringFromIsoDateTime(match.started_at, true);
      }
      return "";
    },
    displayMatchButton(type, row) {
      if (!this.mentorRoleInMatch(row) || !this.menteeRoleInMatch(row)) {
        return false;
      }
      if (
        type === "lockButton" &&
        (row.status_id === matchStatus.LOCKED ||
        row.status_id === matchStatus.SCHEDULED)
      ) {
        return true;
      }
      if (
        type === "unlockButton" &&
        (row.status_id !== matchStatus.LOCKED &&
          row.status_id !== matchStatus.SCHEDULED)
      ) {
        return true;
      }
      return false;
    },
    getLockStatus(row) {
      if (!this.mentorRoleInMatch(row) || !this.menteeRoleInMatch(row)) {
        return null;
      }
      if (
        row.status_id === matchStatus.LOCKED ||
        row.status_id === matchStatus.SCHEDULED
      ) {
        return "Locked";
      }
      return "Unlocked";
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, perPage, page, roleAlias, userRoles
    };
  }
};
</script>
  
  <style lang="scss">
  @import '@/assets/scss/vue/libs/vue-good-table.scss';
  .nav .dropdown-toggle:not(.active)::after {
    background-image: none !important;
  }
  </style>
<style lang="scss" scoped>
    .action-menu {
      display: inline-block;
    }
    ::v-deep .apexcharts-canvas {
      margin: auto;
    }
    .bulk-selection-wrapper {
    background: none !important;
    font-size: 14px;
    color: inherit !important;
  }
  ::v-deep .vgt-selection-info-row{
    background: none;
    font-size: 14px;
    color: inherit;
  }
  .badge-secondary {
    background: #eeecec;
    border: 2px solid #403f3f;
  }
  .b-avatar {
    color: #403f3f;
  }
  .badge-secondary.white { 
    background: #FFF;
    border: 2px solid #403f3f;
  }
  .progress{
    flex:1;
    margin-right: 10px;
  }
</style>