<template>
  <b-row>
    <b-col sm="12">
      <b-row>
        <b-col sm="9">
          <h4 class="text-left font-weight-bolder">
            Matching Questions
          </h4>
        </b-col>
        <b-col
          sm="3"
          class="text-right p-0"
        >
          <b-button
            v-b-toggle="`collapse-matching`"
            variant="outline-none"
            class="btn-icon"
          >
            <feather-icon
              icon="ChevronDownIcon"
              size="20"
            />
          </b-button>
        </b-col>
      </b-row>
        
      <b-collapse
        id="collapse-matching"
        v-model="visible"
        class="mt-0"
        accordion="other-questions"
        role="tabpanel"
      >
        <ul class="list-style-none">
          <li
            v-for="question in matchingQuestionsList"
            :key="question.id"
            class="mt-50"
          >
            <div
              class="list-style-none"
            >
              <p :class="appTextDirection">
                <b>{{ getQuestionTextTranslation(question.translations) }}</b>
              </p>
              <p
                :class="appTextDirection"
                class=""
              >
                <span v-if="!getQuestionChoicesAnswerTextTranslation(question)">&nbsp;</span>
                <ul class="list-style-none mb-1">
                  <li
                    v-for="(q, index) in getQuestionChoicesAnswerTextTranslation(question)"
                    :key="index"
                  >
                    <span :class="{ 'text-success': isMatchQuestionEqual(q), 'text-danger': !isMatchQuestionEqual(q) }">
                      {{ q.choice_text }}
                      <span v-if="q.answer.answer_text"><br>{{ q.answer.answer_text }}</span>
                    </span>
                  </li>
                </ul>
              </p><hr style="margin: 0 -1.5rem;">
            </div>
          </li>
        </ul>
      </b-collapse>
    </b-col>
    <b-col
      sm="12"
      class="mt-1"
    >
      <b-row>
        <b-col sm="9">
          <h4 class="text-left font-weight-bolder">
            Other Questions
          </h4>
        </b-col>
        <b-col
          sm="3"
          class="text-right p-0"
        >
          <b-button
            v-b-toggle="`collapse-other`"
            variant="outline-none"
            class="btn-icon"
          >
            <feather-icon
              icon="ChevronDownIcon"
              size="20"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-collapse
        id="collapse-other"
        class="mt-0"
        accordion="other-questions"
        role="tabpanel"
      >
        <ul class="list-style-none">
          <li
            v-for="question in otherQuestionsList"
            :key="question.id"
            class="mt-50"
          >
            <div
              class="list-style-none"
            >
              <p :class="appTextDirection">
                <b>{{ getQuestionTextTranslation(question.translations) }}</b>
              </p>
              <p
                :class="appTextDirection"
                class="mb-50"
              >
                <span v-if="(question.type_id === questionTypes.TEXT && question.answers.length > 0)">{{ question.answers[0].answer_text }}</span>
                <span v-if="(question.type_id === questionTypes.SCALE)">{{ getStatementsChoicesTranslation(question) }}</span>
                <span v-if="(question.type_id === questionTypes.CHOICE)">
                  <ul class="list-style-none mb-1">
                    <li
                      v-for="(q, index) in getQuestionChoicesAnswerTextTranslation(question)"
                      :key="index"
                    >
                      {{ q.choice_text }}
                      <span v-if="q.answer.answer_text"><br>{{ q.answer.answer_text }}</span>
                    </li>
                  </ul>
                </span>
                <span v-else>&nbsp;</span>
              </p>
              <hr style="margin: 0 -1.5rem;">
            </div>
          </li>
        </ul>
      </b-collapse>
    </b-col>
  </b-row>      
</template>
      
<script>
import {
  BRow,
  BCol,
  BCollapse,
  VBToggle,
  BButton
} from "bootstrap-vue";
import { mapGetters } from 'vuex';
import { questionTypes } from '@models/questionTypes';
import { userRoles } from "@models";
  
export default {
  name: "ApplicationSummary",
  components: {
    BRow,
    BCol,
    BCollapse, 
    BButton
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    user: {
      type: Object,
      default:() => {},
      required: true
    },
    questions: {
      type: Array,
      default:() => [],
      required: true
    },
    localeId: {
      type: Number,
      required: true
    },
    matchedUsersQuestions: {
      type: Array,
      default:() => [],
      required: true
    },
    textDirection: {
      type: String,
      default: "text-left"
    }
  },
  data() {
    return {
      visible: true
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId']),
    appTextDirection() {
      if(this.textDirection) {
        return this.textDirection;
      }
      return this.user.role_id === userRoles.MENTOR ? 'text-left': 'text-right';
    },
    matchingQuestionsList () {
      const  u = this.matchedUsersQuestions.filter(q => q.type_id === questionTypes.MATCHING);
      const y = this.questions.filter(q => q.type_id === questionTypes.MATCHING);
      const result = y.sort((a, b) => {
        const aMatch = u.find(item => item.question_matched_pair_id === a.question_matched_pair_id);
        const bMatch = u.find(item => item.question_matched_pair_id === b.question_matched_pair_id);
        const aValue = aMatch ? aMatch.question_matched_pair_id : 0; 
        const bValue = bMatch ? bMatch.question_matched_pair_id : 0; 
        return aValue - bValue;
      });
      return result;
    },
    otherQuestionsList() {
      return this.questions.filter(q => q.type_id !== questionTypes.MATCHING);
    }
  },  
  methods: {
    isMatchQuestionEqual(matchingQuestion) {
      const matchQuestion = this.matchedUsersQuestions.filter(q => q.answers.length > 0 && q.type_id === questionTypes.MATCHING);
      const found = matchQuestion.filter(mq => mq.choices.find(c => c.matched_choices.find(mc => mc.id === matchingQuestion.question_choice_id)));    
      if(found && found.length > 0) {
        const matchedAns = found[0].answers.map(a => a.choice_id);
        const matchedChoices = found[0];
        const t = matchedChoices.choices.filter(c => matchedAns.includes(c.id));
        if(t.length > 0) {
          return t.filter( c => c.matched_choices.find(mc => mc.id === matchingQuestion.question_choice_id)).length > 0;
        }
      }
      return false;
    },
    getQuestionTextTranslation(translations) {
      const tabTranslation = translations.filter(t => t.locale_id === this.localeId);
      if(tabTranslation.length > 0) {
        return tabTranslation[0].question_text;
      }
      return '';
    },
    getQuestionChoicesAnswerTextTranslation(question) {
      if(question.choices.length > 0 && question.answers.length > 0) {
        return question.answers.map(a => {
          const choice = question.choices.find(c => c.id === a.choice_id);
          const translation = choice?.translations.find(t => t.locale_id === this.localeId) || {};
          return { choice_text: translation.choice_text || '', ...translation, choice, answer: a };
        });
      }
      return;
    },
    getStatementsChoicesTranslation(question) {
      if(question.statements.length > 0 ) {
        const state = question.statements.filter(s => s.answers.length > 0);
        // find the translated choice
        if(state.length > 0) {
          const matchedChoice = question.choices.filter(c => c.id === state[0].answers[0].choice_id).shift();
          const choiceTrans = matchedChoice.translations.filter(t => t.locale_id === this.localeId).shift();
          const stateTrans = state[0].translations.filter(t => t.locale_id === this.localeId).shift();
          if(choiceTrans && stateTrans) {
            return `${stateTrans.question_text} - ${choiceTrans.choice_text}`;
          }
        }
        return;
      }
      return;
    }
  },
  setup() {
    return {
      questionTypes
    };
  },
};
</script>
  <style lang="scss" scoped>
  .list-style-none {
    list-style: none;
  }
  ul {
      margin: 0;
      padding:0;
  }
  </style>