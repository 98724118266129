<template><section /></template> 
      
<script>
import { loaderTypes } from '@models';
export default {
  name: 'AomSkeletonLoader',
  props: {
    loaderType: {
      type: Number,
      default: loaderTypes.LONG
    }
  },
  computed: {
    isLongLoader() {
      return this.loaderType === loaderTypes.LONG;
    },
    isShortLoader() {
      return this.loaderType === loaderTypes.SHORT;
    }
  }
};
</script>
