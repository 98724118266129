import { ref } from '@vue/composition-api';

export default function useAomTableConfig() {
  const total = ref(0);
  const perPage = ref(100);
  const page = ref(1);

  return {
    total,
    perPage,
    page
  };
}
