<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-modal
      id="unpublishMatch"
      ref="unpublishMatch"
      v-model="showModal"
      no-close-on-esc
      no-close-on-backdrop
      title="Cancel Match"
      centered
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <p>
        {{ message }}
      </p>
      <b-row>
        <b-col sm="12">
          <validation-provider
            v-slot="validationContext"
            ref="reason"
            rules="required"
            name="Reason"
          >
            <v-select
              id="reason"
              v-model="note"
              label="note"
              placeholder="Cancellation reason"
              :options="reasons"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col sm="12">
          <b-form-textarea
            v-if="isOtherSelected"
            v-model="otherReason"
            placeholder="Reason for cancellation"
            tabindex="0"
            class="form-control"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel}">
        <b-button
          size="md"
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          size="md"
          variant="primary"
          @click="ok"
        >
          <b-spinner
            v-if="isSubmitting"
            small
          />
          <span
            v-if="isSubmitting"
          > Updating...</span>
          <span v-else>Unpublish Match</span>
        </b-button>
      </template>
    <!-- eslint-disable vue/no-v-html -->

    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { matchStatus } from "@/models";
import AomDatePicker from "@aom-core/AomDatePicker";
import { localDateAndTimeToUtc } from "@/libs/utils";
import { matchesService } from "@services";
import { makeErrorToast, makeSuccessToast, getValidationState } from "@/libs/utils";
//eslint-disable-next-line
import { required } from "@validations";
import vSelect from "vue-select";
import {
  BSpinner,
  BFormTextarea,
  BForm,
  BModal,
  BButton,
  BFormInvalidFeedback,
  BFormTimepicker,
  BFormRadioGroup,
  BFormGroup,
  BRow,
  BCol,
} from "bootstrap-vue";
import { matchCancellationReasons } from "./matchCancellationReasons";

export default {
  components: {
    vSelect,
    BSpinner,
    BFormTextarea,
    BModal,
    BButton,
    BFormInvalidFeedback,
    BForm,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    BFormTimepicker,
    AomDatePicker,
    BFormRadioGroup,
    BRow,
    BCol,
  },
  props: {
    isCreate: {
      type: Boolean,
      default: false,
    },
    matches: {
      type: Array,
      default: () => [],
    },
    programId: {
      type: [Number, String],
      default: 0,
    }
  },
  computed: {
    isOtherSelected() {
      return this.note !== null && this.note.isOther;
    },
    message () {
      return this.matches.length > 1? "Are you sure you want to Unpublish these matches? This will sever the match and cannot be undone. Matches that are over 30 days into a program will still be counted in your program match capacity. This action will only apply to currently Active Matches, all other selections are ignored in this action. Do you wish to continue?"
        : "Are you sure you want to Unpublish this match? This will sever the match and cannot be undone. Matches that are over 30 days into a program will still be counted in your program match capacity. Do you wish to continue?";
    }
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      selectedMatchStatus: matchStatus.ACTIVE,
      matchStartDate: new Date(),
      matchStartTime: "09:00",
      minStartDate: new Date(),
      note: null,
      isSubmitting: false,
      reasons: matchCancellationReasons,
      otherReason: ""
    };
  },
  methods: {
    open() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    async onSubmit() {
      this.isSubmitting = true;
      const programId = this.programId;
      let result = null;
      if (this.matches.length === 1) {
        let data = { ...this.matches[0], status_id: matchStatus.CANCELLED, closing_note: this.isOtherSelected? this.otherReason : this.note.note};
        result = await matchesService.patchUpdateMatch(programId, this.matches[0].id, data);
      } else {
        let data = {
          matches: this.matches.map(m => ({...m, status_id: matchStatus.CANCELLED, closing_note: this.isOtherSelected? this.otherReason : this.note.note}))
        };
        result = await matchesService.patchUpdateMatches(programId, data);
        
      }
      if (result) {
        this.$toast(makeSuccessToast('Match Unpublished successfully.'));
      }
      this.$emit("unpublished");
      this.showModal = false;
      this.isSubmitting = false;
    },
    clearForm() {
      this.showModal = false;
      this.isSubmitting = false;
    },
  },
  setup() {
    return {
      getValidationState
    };
  }
};
</script>

<style>
</style>